import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { css, useTheme } from '@emotion/react'
import styled from '@emotion/styled'
import {Logo} from '~components/Svg'
import { mobile } from '../styles/global'
import Menu from './Menu'
import { useSiteState, useCart } from '~context/siteContext'
import Num from '~components/Num'

const Header = ({ className, transparent, currentSlug }) => {
	const [siteState, setSiteState] = useSiteState()
	const { cartCount } = useCart()
	const [view, setView] = useState(undefined)
	const [active, setActive] = useState(undefined)
	const [underlinedLink, setUnderlinedLink] = useState(undefined)

	const navlinks =[{name: 'store'}, {name: 'about'}, {name: 'journal'}]

	const openMenu = (menuView) => {
		if(menuView === view){
			setSiteState(prevState => ({
				...prevState,
				menuOpen: false
			}))
			setTimeout(()=>{
				setView(undefined)
			}, 800)
		} else {
			if(siteState.cartOpen){
				setSiteState(prevState => ({
					...prevState,
					cartOpen: false
				}))
			}
			setSiteState(prevState => ({
				...prevState,
				menuOpen: true
			}))
			setView(menuView)
		}
	}

	const openCart = ()=> {
		if(siteState.menuOpen || siteState.mobileNavOpen){
			setSiteState(prevState => ({
				...prevState,
				menuOpen: false,
				mobileNavOpen: false,
				cartOpen: true
			}))
		} else {
			setSiteState(prevState => ({
				...prevState,
				cartOpen: true
			}))
		}
	}

	useEffect(() => {
		if(!siteState.menuOpen){
			setTimeout(()=>{
				setView(undefined)
			}, 800)
		}
	}, [siteState.menuOpen])

	useEffect(() => {
		if (siteState.menuOpen || siteState.mobileNavOpen) {
			setActive(true)
		} else {
			setActive(false)
		}
	}, [siteState.menuOpen, siteState.mobileNavOpen])

	const toggleMenu = ()=> {
		if (!siteState.menuOpen && siteState.mobileNavOpen) {
			setSiteState(prevState => ({
				...prevState,
				mobileNavOpen: false
			}))
		} else if (!siteState.menuOpen) {
			setSiteState(prevState => ({
				...prevState,
				mobileNavOpen: true
			}))
		} else if(siteState.menuOpen) {
			setSiteState(prevState => ({
				...prevState,
				menuOpen: false,
				mobileNavOpen: false
			}))
		}
	}

	useEffect(() => {
		if(view) {
			setUnderlinedLink(view)
		} else {
			if (currentSlug === 'range' || currentSlug === 'brand'){
				setUnderlinedLink('about')
			} else if (currentSlug === 'range' || location.pathname.replace(/\//g,'') === 'brand'){
				setUnderlinedLink('about')
			} else {
				setUnderlinedLink(currentSlug)	
			}
		}
	}, [currentSlug, view])


	return(
		<>
			<Wrap className={className}>
				<HeaderPanel css={css`
					background-color: ${transparent && !active ? 'transparent' :'var(--beige)'};
					height: ${siteState.headerExpanded && !active ? '92px' : '54px'};
					border-color: ${siteState.headerExpanded && !active ? 'transparent' : 'var(--black)'};
					transition-delay: ${transparent && !active ? '0.3s' : '0s'};
				`}>
					<Left>
						<LogoLink to='/'>
							<SiteLogo 
								css={css`
									opacity: ${siteState.headerExpanded && !active ? '0' : '1'};
									pointer-events: ${siteState.headerExpanded && !active ? 'none' : 'all'};
								`}
							>	
								<LogoMask css={css`
									clip-path: url(#clip);
								`}/>
								<svg css={css`width: 0; height: 0;`} aria-hidden="true">
									<defs>
										<clipPath id="clip" clipPathUnits="objectBoundingBox">
											<path d="M0.176,0 C0.136,0,0.113,0.233,0.113,0.5 C0.113,0.767,0.136,1,0.176,1 C0.216,1,0.239,0.768,0.239,0.5 C0.239,0.232,0.216,0,0.176,0 M0.176,0.75 C0.158,0.75,0.148,0.634,0.148,0.5 C0.148,0.366,0.158,0.25,0.176,0.25 C0.195,0.25,0.204,0.366,0.204,0.5 C0.204,0.634,0.195,0.75,0.176,0.75 M1,0.03 H0.966 V0.97 H1 V0.03 M0.769,0.03 V0.239 H0.798 V0.97 H0.833 V0.239 H0.885 V0.97 H0.919 V0.239 H0.949 V0.03 H0.769 M0.752,0.97 H0.678 V0.03 H0.752 V0.239 H0.712 V0.403 H0.752 V0.604 H0.712 V0.761 H0.752 V0.97 M0.625,0.565 L0.591,0.03 H0.556 V0.97 H0.589 V0.436 L0.623,0.97 H0.657 V0.03 H0.625 V0.565 M0.294,0.03 L0.328,0.565 V0.03 H0.361 V0.97 H0.326 L0.292,0.436 V0.97 H0.259 V0.03 H0.294 M0.046,0.762 C0.055,0.762,0.059,0.711,0.059,0.637 V0.029 H0.093 V0.61 C0.093,0.878,0.075,0.996,0.046,0.996 C0.015,0.996,0,0.836,0,0.628 H0.034 C0.034,0.711,0.038,0.762,0.046,0.762"/>
										</clipPath>
									</defs>
								</svg>
							</SiteLogo>
						</LogoLink>
					</Left>
					<Nav>
						{navlinks.map((link, i) => (
							<NavLink 
								key={link.name} onClick={()=> openMenu(link.name)}
								css={css`
									text-decoration: ${underlinedLink === link.name ? 'underline' : 'none'};
								`}
							>
								<Number dangerouslySetInnerHTML={{__html: `&#931${i + 2}`}}/> 
								<span className='h3'>{link.name}</span>
							</NavLink>
						))}
						<Link 
							to='/contact' 
							css={css`
								text-decoration: ${underlinedLink === 'contact' ? 'underline' : 'none'};
								line-height: normal;
						`}>
							<Number dangerouslySetInnerHTML={{__html: `&#931${navlinks?.length + 2}`}}/> 
							<span className='h3'>contact</span>
						</Link>
					</Nav>
					<Right>
						<Bag 
							onClick={() => openCart()}>								
							<span className="h3">Bag</span> <Counter>{cartCount()}</Counter>
						</Bag>
						<Hamburger onClick={()=> toggleMenu()}>
							<Line css={css`width: ${siteState.mobileNavOpen ? '0px' : '28px'};`}/>
							<Line/>
							<Line css={css`width: ${siteState.mobileNavOpen ? '0px' : '28px'};`}/>
						</Hamburger>
					</Right>
				</HeaderPanel>
				<NavMobile 
					css={css`
						transform: ${siteState.mobileNavOpen ? 'translateY(0)' : 'translateY(-100%)'};
						opacity: ${transparent && !active ? '0' : '1'};
						${mobile}{
							transition-delay: 0s, ${transparent && !active ? '0s' : siteState.mobileNavOpen ? '0s' : '0.3s'};
						}
				`}>
					{navlinks.map((link, i) => (
						<NavLink 
							key={link.name} onClick={()=> openMenu(link.name)}
							css={css`
							text-decoration: ${underlinedLink === link.name ? 'underline' : 'none'};
					`}>
							<Number dangerouslySetInnerHTML={{__html: `&#931${i + 2}`}}/> 
							<span className='h3'>{link.name}</span>
						</NavLink>
					))}
					<Link 
						to='/contact' 
						css={css`
							text-decoration: ${underlinedLink === 'contact' ? 'underline' : 'none'};
						`}>
						<Number dangerouslySetInnerHTML={{__html: `&#931${navlinks?.length + 2}`}}/> 
						<span className='h3'>contact</span>
					</Link>
				</NavMobile>
				<MainMenu 
					view={view} 
					css={css`
						transform: ${siteState.menuOpen && view ? 'translateY(0)' : 'translateY(calc(-100% - 54px))'};
						opacity: ${transparent && !active ? '0' :'1'};
						${mobile}{
							transform: ${siteState.menuOpen && view ? 'translateY(0)' : 'translateY(calc(-100% - 46px))'};
						}
					`}
				/>
				<LandingState css={css`
						opacity: ${siteState.headerExpanded && !active ? '1' : '0'};
						pointer-events: ${siteState.headerExpanded && !active ? 'all' : 'none'};
					`}>
					<LargeLogo/>
				</LandingState>
			</Wrap>
		</>
	)
}

const Wrap = styled.header`
  position: fixed;
  top: 0;
	left: 0;
  right: 0;
  z-index: 100;
	${mobile}{
		height: 46px;
	}
`
const HeaderPanel = styled.div`
	border-bottom: 1px solid;
	display: grid;
	grid-template-columns: 1fr max-content 1fr;
	align-items: center;
	padding: 0 40px;
	position: relative;
	z-index: 3;
	transition: height 0.3s, border-color 0.3s, background-color 0.3s;
	${mobile}{
		grid-template-columns: 1fr max-content;
		padding: 0 20px;
		height: 46px;
	}
`
const Left = styled.div`
	max-width: 114px;
	${mobile}{
		grid-column: 1/2;
		grid-row: 1;
		max-width: 87px;
	}
`
const LogoLink = styled(Link)`
	display: block;
`
const SiteLogo = styled.div`
	height: 0px;
	width: 100%;
	display: block;
	padding-bottom: 12.076%;
	position: relative;
	transition: opacity 0.3s;
	:hover {
		>div {
			background: linear-gradient(70deg, var(--black) 33%, #4b4b4b 50%, var(--black) 66%);
			background-size: 300% 100%;
			animation: shimmer 0.5s linear;
		}
	}
`
const LogoMask = styled.div`
	background-color: black;
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
`

const Number = styled.span`
	display: inline-block;
	margin-right: var(--xxs);
	${mobile}{
		grid-column: 2/3;
		justify-self: end;
		grid-row: 1;
	}
`
const Nav = styled.nav`
	display: flex;
	${mobile}{
		display: none;
	}
`
const NavLink = styled.button`
	margin-right: var(--xl);
	${mobile}{
		margin-right: 0px;
	}
`

const Bag = styled.button`
`
const Right = styled.div`
	justify-self: end;
	${mobile}{
		display: grid;
		grid-template-columns: repeat(2, max-content);
		align-items: center;
	}
`
const Counter = styled(Num)`
	margin-left: var(--xxs);
`
const Hamburger = styled.button`
	display: flex;
	flex-direction: column;
`
const Line = styled.div`
	display: none;
	height: 1px; 
	width: 28px;
	background-color: var(--black);
	margin-left: 12px;
	margin-bottom: 3px;
	transition: width 0.3s;
	&:last-of-type{
		margin-bottom: 0px
	}
	${mobile}{
		display: inline-block;
	}
`
const NavMobile = styled.div`
	display: none;
	${mobile}{
		display: block;
		height: 46px;
		display: flex;
		align-items: center;
		border-bottom: 1px solid var(--black);
		padding: 0 20px; 
		justify-content: space-between;
		background: var(--beige);
		transition: transform 0.3s, opacity 0.3s;
		position: relative;
		z-index: 2;
	}
`
const MainMenu = styled(Menu)`
	transition: transform 0.8s, opacity 0.3s;
`
const LandingState = styled.div`
	position: fixed;
	top: 92px;
	left: 0;
	right: 0;
	transition: opacity 0.3s 0.1s;
	padding: var(--s);
`
const LargeLogo = styled(Logo)`
	margin: 0 auto;
	max-width: 252px;
	display: block;	
`
Header.propTypes = {
	className: PropTypes.string,
	landingState: PropTypes.bool,
	transparent: PropTypes.bool,
	currentSlug: PropTypes.string,
}

export default Header
